            <!-- forced sticky header start -->
function toggleStickyHeader() {
    const header = document.querySelector('.custom-header');
    const stickyClass = 'home-page-sticky-header';
    
    if (window.scrollY > 100) { 
        if (!document.body.classList.contains(stickyClass)) {
            document.body.classList.add(stickyClass);
        }
    } else {
        if (document.body.classList.contains(stickyClass)) {
            document.body.classList.remove(stickyClass);
        }
    }
}

window.onscroll = function() {
    toggleStickyHeader();
};

   
    <!-- forced sticky header end --> 